import axios from "axios";
import {SITEKICK_BASE_URL, SITEKICK_KEY} from "../configs/AppConfig";

const sitekickService = {}

/**
 * Create new Axios connection to Sitekick
 * @type {AxiosInstance}
 */
const sitekickConnection = axios.create({
    baseURL: SITEKICK_BASE_URL,
    headers: {
        'Accept-Language': 'nl-NL, nl;q=0.9',
        'Authorization': SITEKICK_KEY,
        'Accept': 'application/json'
    }
})
// always response data
sitekickConnection.interceptors.response.use((response) => {
    return response.data
});

/**
 * Fetch news items
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
sitekickService.getNews = (page = 1) => {
    return sitekickConnection.get('news?page=' + page);
}
/**
 * Get single news item by slug
 * @param slug
 * @returns {Promise<AxiosResponse<any>>}
 */
sitekickService.getNewsItem = (slug) => {
    return sitekickConnection.get('news/' + slug);
}

/**
 * Get FAQ items
 * @returns {Promise<AxiosResponse<any>>}
 */
sitekickService.getFaq = () => {
    return sitekickConnection.get('faq');
}

/**
 * Get menu from Sitekick
 * @returns {Promise<AxiosResponse<any>>}
 */
sitekickService.getMenu = () => {
    return sitekickConnection.get('menu');
}

/**
 * Get single page from Sitekick
 * @param slug
 * @returns {Promise<AxiosResponse<any>>}
 */
sitekickService.getPage = (slug) => {
    return sitekickConnection.get('page/' + slug);
}

export default sitekickService;