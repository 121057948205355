import fetch from 'auth/FetchInterceptor'

const userService = {}

userService.getUser = function (params) {
    return fetch({
        url: '/login_check',
        method: 'get',
        params
    })
}

userService.requestPassword = function (params) {
    return fetch({
        url: '/request-password',
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json'
        },
        data: params
    })
}

userService.setPassword = function (params) {
    return fetch({
        url: '/set-password',
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json'
        },
        data: params
    })
}

userService.resetPassword = function (params) {
    return fetch({
        url: '/login_check',
        method: 'get',
        data: params
    })
}

export default userService