import React, {Component} from "react";
import {Avatar, Dropdown, Menu} from "antd";
import {connect} from 'react-redux'
import {EditOutlined, LogoutOutlined} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {signOut} from '../../redux/actions/Auth';
import {Link, withRouter} from "react-router-dom";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";

class NavProfile extends Component {
    /**
     * Logout method
     */
    doLogout() {
        this.props.signOut();
        this.props.history.push('/auth/login');
    }

    /**
     * Render profile menu
     * @returns {JSX.Element}
     */
    profileMenu() {

        return (
            <div className="nav-profile nav-dropdown">
                <div className="nav-profile-header">
                    <div className="d-flex">
                        <Avatar style={{color: '#fff', backgroundColor: '#6FB62E'}}>
                            {this.props.user.firstName.substr(0, 1)}
                        </Avatar>
                        <div className="ml-3">
                            <h4 className="mb-0">{this.props.user && this.props.user.firstName + ' ' + this.props.user.lastName}</h4>
                            <p>Welkom!</p>
                        </div>
                    </div>
                </div>
                <div className="nav-profile-body">
                    <Menu>
                        <Menu.Item key="profile-nav-0">
                            <Link
                                to={`${APP_PREFIX_PATH}/profile/edit`}>
                                <Icon className="mr-3" type={EditOutlined}/>
                                <span className="font-weight-normal">{"Profiel bewerken"}</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="profile-nav-2" onClick={e => this.doLogout()}>
                            <span>
                              <LogoutOutlined className="mr-3"/>
                              <span className="font-weight-normal">Uitloggen</span>
                            </span>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        );
    }

    /**
     * Render template
     * @returns {JSX.Element}
     */
    render() {
        return (
            <Dropdown placement="bottomRight" overlay={this.profileMenu.bind(this)} trigger={["click"]}>
                <Menu className="d-flex align-item-center" mode="horizontal">
                    <Menu.Item>
                        <Avatar style={{color: '#fff', backgroundColor: '#6FB62E'}}>
                            {this.props.user.firstName.substr(0, 1)}
                        </Avatar>
                    </Menu.Item>
                </Menu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({auth}) => {
    const {user} = auth;
    return {user}
};

const mapDispatchToProps = {
    signOut
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavProfile))