import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {useStore} from "react-redux";
import NavigationConfig from "configs/NavigationConfig";

export const AdminViews = () => {

    const store = useStore();
    const state = store.getState();
    const userRole = state.auth.user.roles[0];

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                {NavigationConfig[userRole].map((row, key) => {
                    let subRoutes = [];
                    if (row.submenu) {
                        subRoutes = row.submenu.map((subRow, subkey) => {
                            return (<Route path={subRow.path} key={`route-sub-${subkey}`}
                                           component={lazy(() => subRow.component)}/>)
                        })
                    }
                    return [...subRoutes,
                        <Route exact path={row.path} key={`route-${key}`} component={lazy(() => row.component)}/>]
                })}
                <Route exact path={`${APP_PREFIX_PATH}/news`} key={`route-news`}
                       component={lazy(() => import('../shared-views/news'))}/>
                <Route exact path={`${APP_PREFIX_PATH}/news/:slug`} key={`route-news-detail`}
                       component={lazy(() => import('../shared-views/news/view'))}/>
                <Route exact path={`${APP_PREFIX_PATH}/page/:slug`} key={`route-news-detail`}
                       component={lazy(() => import('../shared-views/pages/view'))}/>
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`}/>
            </Switch>
        </Suspense>
    )
}

export default React.memo(AdminViews);