import {
    DashboardOutlined,
    HeartOutlined,
    IdcardOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    LaptopOutlined, FilePdfOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const navigationConfig = {
    "ROLE_ADMIN": [
        {
            key: 'home',
            component: import('../views/admin-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: 'profile_edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'profile_edit',
            icon: UserOutlined,
            breadcrumb: false,
            in_menu: false,
            submenu: []
        },
        {
            key: 'certificates',
            component: import('../views/admin-views/certificates/index'),
            path: `${APP_PREFIX_PATH}/certificates`,
            title: 'certificates',
            icon: FilePdfOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'certificates_edit',
                    component: import('../views/admin-views/certificates/edit'),
                    path: `${APP_PREFIX_PATH}/certificates/edit/:id`,
                    title: 'certificates.edit',
                    icon: FilePdfOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'quality_mark_owners',
            component: import('../views/admin-views/quality_mark_owners/index'),
            path: `${APP_PREFIX_PATH}/quality-mark-owners`,
            title: 'quality_mark_owners',
            icon: HeartOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'quality_mark_owners_add',
                    component: import('../views/admin-views/quality_mark_owners/add'),
                    path: `${APP_PREFIX_PATH}/quality-mark-owners/add`,
                    title: 'quality_mark_owners.add',
                    icon: HeartOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'quality_mark_owners_edit',
                    component: import('../views/admin-views/quality_mark_owners/edit'),
                    path: `${APP_PREFIX_PATH}/quality-mark-owners/edit/:id`,
                    title: 'quality_mark_owners.edit',
                    icon: HeartOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'systems',
            component: import('../views/admin-views/systems/index'),
            path: `${APP_PREFIX_PATH}/systems`,
            title: 'systems',
            icon: LaptopOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'systems_add',
                    component: import('../views/admin-views/systems/add'),
                    path: `${APP_PREFIX_PATH}/systems/add`,
                    title: 'systems.add_new',
                    icon: LaptopOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'systems_edit',
                    component: import('../views/admin-views/systems/edit'),
                    path: `${APP_PREFIX_PATH}/systems/edit/:id`,
                    title: 'systems.edit',
                    icon: LaptopOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'users',
            component: import('../views/admin-views/users/index'),
            path: `${APP_PREFIX_PATH}/users`,
            title: 'users',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'users_add',
                    component: import('../views/admin-views/users/add'),
                    path: `${APP_PREFIX_PATH}/users/add`,
                    title: 'users.add_new',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'users_edit',
                    component: import('../views/admin-views/users/edit'),
                    path: `${APP_PREFIX_PATH}/users/edit/:id`,
                    title: 'users.edit',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
    ],
    "ROLE_USER_EDITOR": [
        {
            key: 'home',
            component: import('../views/user-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: 'profile_edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'profile_edit',
            icon: UserOutlined,
            breadcrumb: false,
            in_menu: false,
            submenu: []
        },
        {
            key: 'certificates',
            component: import('../views/user-views/certificates/index'),
            path: `${APP_PREFIX_PATH}/certificates`,
            title: 'certificates',
            icon: FilePdfOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'certificates_add',
                    component: import('../views/user-views/certificates/add'),
                    path: `${APP_PREFIX_PATH}/certificates/add`,
                    title: 'certificates.add',
                    icon: FilePdfOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'certificates_edit',
                    component: import('../views/user-views/certificates/edit'),
                    path: `${APP_PREFIX_PATH}/certificates/edit/:id`,
                    title: 'certificates.edit',
                    icon: FilePdfOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'users',
            component: import('../views/user-views/users/index'),
            path: `${APP_PREFIX_PATH}/users`,
            title: 'users',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'users_add',
                    component: import('../views/user-views/users/add'),
                    path: `${APP_PREFIX_PATH}/users/add`,
                    title: 'users.add_new',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'users_edit',
                    component: import('../views/user-views/users/edit'),
                    path: `${APP_PREFIX_PATH}/users/edit/:id`,
                    title: 'users.edit',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
    ],
    "ROLE_USER_READ": [
        {
            key: 'home',
            component: import('../views/user-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: false,
            submenu: []
        },
    ]
};

export default navigationConfig;
