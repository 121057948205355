import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const APP_NAME = 'Stichting Keurmerk Ritregistratiesystemen';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const SITEKICK_BASE_URL = env.SITEKICK_ENDPOINT_URL
export const SITEKICK_KEY = env.SITEKICK_KEY
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'nl',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#6FB62E',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
};
