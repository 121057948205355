const dev = {
    API_ENDPOINT_URL: 'https://127.0.0.1:8000',
    SITEKICK_ENDPOINT_URL: 'https://skrrs.previewutrecht.nobearshosting.nl/skrrs/api',
    SITEKICK_KEY: 'SAjk34jk54jskJDASLFLk32',
};

const prod = {
    API_ENDPOINT_URL: 'https://api.keurmerkritregistratiesystemen.nl',
    SITEKICK_ENDPOINT_URL: 'https://keurmerkritregistratiesystemen.nl/skrrs-api',
    SITEKICK_KEY: 'SAjk34jk54jskJDASLFLk32',
};

const preview = {
    API_ENDPOINT_URL: 'https://skrrs-api.nobearshosting.nl',
    SITEKICK_ENDPOINT_URL: 'https://skrrs.previewutrecht.nobearshosting.nl/skrrs-api',
    SITEKICK_KEY: 'SAjk34jk54jskJDASLFLk32'
};

const getEnv = () => {
    switch (process.env.REACT_APP_API) {
        case 'preview':
            return preview
        case 'production':
            return prod
        case 'dev':
        default:
            return dev
    }
}

export const env = getEnv()
