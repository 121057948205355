import {
    AUTH_TOKEN,
    AUTH_USER_TYPE,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNIN_AS,
    SIGNIN_WITH_FACEBOOK,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SIGNIN_WITH_GOOGLE,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNOUT,
    SIGNOUT_AS,
    SIGNOUT_SUCCESS,
    SIGNUP,
    SIGNUP_SUCCESS
} from '../constants/Auth';

export const signIn = (user) => {
    localStorage.setItem(AUTH_USER_TYPE, JSON.stringify(user.roles))
    return {
        type: SIGNIN,
        user
    }
};

export const signInAs = (user) => {
    localStorage.setItem(AUTH_USER_TYPE, JSON.stringify(user.roles))
    localStorage.setItem(SIGNIN_AS, user.email);
    return {
        type: SIGNIN_AS,
        user
    }
}

export const authenticated = (token) => {
    localStorage.setItem(AUTH_TOKEN, token)
    return {
        type: AUTHENTICATED,
        token
    }
};

export const signOut = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(SIGNIN_AS);
    return {
        type: SIGNOUT
    };
};

export const signOutAs = () => {
    localStorage.removeItem(SIGNIN_AS);
    window.location.href = '/app/partners';
    return {
        type: SIGNOUT_AS
    }
}

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS,
    }
};

export const signUp = (user) => {
    return {
        type: SIGNUP,
        payload: user
    };
};

export const signUpSuccess = (token) => {
    return {
        type: SIGNUP_SUCCESS,
        token
    };
};

export const signInWithGoogle = () => {
    return {
        type: SIGNIN_WITH_GOOGLE
    };
};

export const signInWithGoogleAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
        token
    };
};

export const signInWithFacebook = () => {
    return {
        type: SIGNIN_WITH_FACEBOOK
    };
};

export const signInWithFacebookAuthenticated = (token) => {
    return {
        type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
        token
    };
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_AUTH_MESSAGE,
        message
    };
};

export const hideAuthMessage = () => {
    return {
        type: HIDE_AUTH_MESSAGE,
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING,
    };
};
