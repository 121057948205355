import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Grid, Menu} from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import {connect} from "react-redux";
import {NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from "constants/ThemeConstant";
import utils from 'utils'
import {onMobileNavToggle} from "redux/actions/Theme";
import {signOutAs} from "redux/actions/Auth";
import {
    CalendarOutlined,
    FileTextOutlined,
    DownloadOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import sitekickService from "../../services/SitekickService";

const {SubMenu} = Menu;
const {useBreakpoint} = Grid;

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

const sitekickMenuIcons = {
    'FileTextOutlined': FileTextOutlined,
    'DownloadOutlined': DownloadOutlined,
    'QuestionCircleOutlined': QuestionCircleOutlined,
}

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent = (props) => {
    const {sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle} = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
    const [menu, setMenu] = useState(0);
    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false)
        }
    }

    useEffect(() => {
        // get extra navigation content from
        sitekickService.getMenu().then((result) => {
            setMenu(result.pages);
        })
    }, []);

    return (
        <Menu
            theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
            mode="inline"
            style={{height: "100%", borderRight: 0, paddingTop: 20}}
            defaultSelectedKeys={[routeInfo?.key]}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
            className={hideGroupTitle ? "hide-group-title" : ""}
        >
            {navigationConfig[props.user.roles[0]] && navigationConfig[props.user.roles[0]].map((menu) => {
                    if (!menu.in_menu) return '';

                    let subMenu = [];
                    if (menu.submenu.length > 0) {
                        menu.submenu.forEach((subitem) => {
                            if (subitem.in_menu) {
                                subMenu.push(<Menu.Item key={subitem.key}>
                                    <span>{setLocale(localization, subitem?.title)}</span>
                                    {subitem.path ? <Link onClick={() => closeMobileNav()} to={subitem.path}/> : null}
                                </Menu.Item>)
                            }
                        });
                    }

                    // Show collapse submenu
                    if (subMenu.length > 0) {
                        return (
                            <SubMenu key={menu.key} icon={<Icon type={menu?.icon}/>}
                                     title={setLocale(localization, menu?.title)}>
                                {subMenu}
                            </SubMenu>
                        );
                        // Normal menu
                    } else {
                        return (
                            <Menu.Item key={menu.key}>
                                {menu.icon ? <Icon type={menu?.icon}/> : null}
                                <span>{setLocale(localization, menu?.title)}</span>
                                {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path}/> : null}
                            </Menu.Item>
                        );
                    }
                }
            )}

            {menu && menu.map((menuItem, key) => {
                return (<Menu.Item key={`menu-page-${key}`} className={key === 0 ? 'mt-4' : ''}>
                    <Icon type={sitekickMenuIcons[menuItem.icon]}/>
                    <span>{menuItem.name}</span>
                    <Link to={`${APP_PREFIX_PATH}/page/${menuItem.slug}`}/>
                </Menu.Item>);
            })}

            <Menu.Item key={"news"} className={navigationConfig[props.user.roles[0]].length && !menu ? 'mt-4' : ''}>
                <Icon type={CalendarOutlined}/>
                <span>Nieuws</span>
                <Link to={`${APP_PREFIX_PATH}/news`}/>
            </Menu.Item>

        </Menu>
    );
};

const TopNavContent = (props) => {
    const {topNavColor, localization, user} = props;
    return (
        <Menu mode="horizontal" style={{backgroundColor: topNavColor}}>
            {navigationConfig[user.roles[0]].map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                {menu.icon ? <Icon type={menu?.icon}/> : null}
                                <span>{setLocale(localization, menu.title)}</span>
              </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        key={subMenuFirst.key}
                                        icon={
                                            subMenuFirst.icon ? (
                                                <Icon type={subMenuFirst?.icon}/>
                                            ) : null
                                        }
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond) => (
                                            <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link to={subMenuSecond.path}/>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        {subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon}/>
                                        ) : null}
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link to={subMenuFirst.path}/>
                                    </Menu.Item>
                                )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon}/> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path}/> : null}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
    ) : (
        <TopNavContent {...props} />
    );
};

const mapStateToProps = ({theme, auth}) => {
    const {sideNavTheme, topNavColor} = theme;
    const {user} = auth;
    return {sideNavTheme, topNavColor, user};
};

export default connect(mapStateToProps, {onMobileNavToggle, signOutAs})(MenuContent);
