import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import {authenticated, signIn} from "../redux/actions/Auth";
import userService from "../services/UserService";
import {AUTH_TOKEN, SIGNIN_AS} from "../redux/constants/Auth";
import {withBodyClass} from "../hooks/withBodyClass";
import apiService from "../services/ApiService";


class Views extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    /**
     * Load default user + token on page refresh
     */
    componentDidMount() {

        if (localStorage.getItem(AUTH_TOKEN) && !this.props.token) {
            this.props.authenticated(localStorage.getItem(AUTH_TOKEN));
        }

        if (this.props.token && !this.props.user) {

            // check if admin was logged in as user
            if (localStorage.getItem(SIGNIN_AS)) {

                // get sign in as profile
                apiService.getItems('users', {email: localStorage.getItem(SIGNIN_AS)}).then((res) => {
                    if (res["hydra:member"].length) {
                        this.props.signIn(res["hydra:member"][0]);
                        this.setState({
                            isLoading: false
                        });
                    }
                })

            } else {
                userService.getUser().then(res => {
                    apiService.getByRid(res.user).then((userData) => {
                        this.props.signIn(userData);
                        this.setState({
                            isLoading: false
                        });
                    })

                });
            }
        } else {
            this.setState({
                isLoading: false
            });
        }
    }

    /**
     * Render view
     * @returns {JSX.Element|boolean}
     */
    render() {

        const {locale, direction, location} = this.props;
        const currentAppLocale = AppLocale[locale];

        // Redirect to login if not logged in
        if (!this.props.token && location.pathname.indexOf(AUTH_PREFIX_PATH) < 0) {
            return <Redirect to={`${AUTH_PREFIX_PATH}/login`}/>;
        }

        return (
            !this.state.isLoading && <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={APP_PREFIX_PATH}/>
                        </Route>
                        <Route path={AUTH_PREFIX_PATH}>
                            <AuthLayout direction={direction}/>
                        </Route>
                        <Route path={APP_PREFIX_PATH}>
                            <AppLayout direction={direction} location={location}/>
                        </Route>
                    </Switch>
                </ConfigProvider>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({theme, auth}) => {
    const {locale, direction} = theme;
    const {token, user} = auth;
    return {locale, token, direction, user}
};

const mapDispatchToProps = {
    authenticated,
    signIn
}

export default withBodyClass(withRouter(connect(mapStateToProps, mapDispatchToProps)(Views)));