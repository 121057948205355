import {
    AUTH_TOKEN,
    AUTH_USER_TYPE,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNIN_AS,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    signin_as: localStorage.getItem(SIGNIN_AS),
    token: localStorage.getItem(AUTH_TOKEN),
    user_type: localStorage.getItem(AUTH_USER_TYPE),
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/',
                token: action.token,
                showMessage: false
            }
        case SIGNIN:
            return {
                ...state,
                user: action.user
            }
        case SIGNIN_AS:
            return {
                ...state,
                user: action.user
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                token: null,
                redirect: '/',
                loading: false
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        default:
            return state;
    }
}

export default auth