import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {AUTH_PREFIX_PATH} from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Switch>
                <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/wachtwoord-vergeten`}
                       component={lazy(() => import(`./authentication/forgot-password`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/wachtwoord-herstellen/:hash`}
                       component={lazy(() => import(`./authentication/set-password`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/wachtwoord-instellen/:hash`}
                       component={lazy(() => import(`./authentication/create-password`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/app/inloggen`}
                       component={lazy(() => import(`./authentication/password-has-been-set`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))}/>
                <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))}/>
                <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`}/>
            </Switch>
        </Suspense>
    )
}

export default AppViews;

