import enLang from './entries/en_US';
import nlLang from './entries/nl_NL';
import moment from "moment";
import 'moment/locale/nl';

moment.locale('nl');
const AppLocale = {
    nl: nlLang,
    en: enLang,
    moment: moment
};

export default AppLocale;